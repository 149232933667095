import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="h2" components={components}>{`What is this website?`}</MDXTag>
<MDXTag name="p" components={components}>{`This website is a companion to a book-length study of college student writers. Developing Writers in Higher Education: A Longitudinal Study follows 169 undergraduates across four years to show how they become more effective writers. `}</MDXTag>
<MDXTag name="p" components={components}>{`If you are a student …`}</MDXTag>
<MDXTag name="p" components={components}>{`If you are a specialist in writing studies … `}</MDXTag>
<MDXTag name="p" components={components}>{`If you are a policy maker or administrator …`}</MDXTag>
<MDXTag name="p" components={components}>{`If you are a writing teacher … `}</MDXTag>
<MDXTag name="p" components={components}>{`If you are a person who writes … `}</MDXTag>
<MDXTag name="p" components={components}>{`this book is for you.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://doi.org/10.3998/mpub.10079890.cmp.323"}}>{`This video`}</MDXTag>{` explains the genesis of the project and how it took shape. The authors also explain how they developed the central questions of their chapters. `}</MDXTag>
<MDXTag name="p" components={components}>{`In their videos, Anne Gere and Naomi Silver discuss the complex and surprising ways that students develop as writers, as well as the impact that a college education makes on writing. To view their videos: `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://doi.org/10.3998/mpub.10079890.cmp.325"}}>{`for students and other writers`}</MDXTag>{`; `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://doi.org/10.3998/mpub.10079890.cmp.326"}}>{`for instructors`}</MDXTag>{`; for `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://doi.org/10.3998/mpub.10079890.cmp.324"}}>{`policy makers and administrators`}</MDXTag>{`. `}</MDXTag>
<MDXTag name="h2" components={components}>{`What is this project?`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Developing Writers in Higher Education: A Longitudinal Study`}</MDXTag>{` represents the outcome of a research project led by the Gayle Morris Sweetland Center for Writing at the University of Michigan. The project  investigates how college students develop as writers across their undergraduate years. `}</MDXTag>
<MDXTag name="p" components={components}>{`The publication of the project consists of three layers: an interactive “engagement layer” that connects the findings of the project to the everyday importance of writing; a “reading layer” that presents the text of the scholarly book `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://dx.doi.org/10.3998/mpub.10079890"}}><MDXTag name="em" components={components} parentName="a">{`Developing Writers in Higher Education: A Longitudinal Study`}</MDXTag></MDXTag>{`, published by the University of Michigan Press; and a “data layer” that provides interviews, surveys, and samples of student writing, including eportfolios, to display the many different ways these students become effective writers. `}</MDXTag>
<MDXTag name="p" components={components}>{`The preparation of this multi-layered publication was enabled by a generous grant from the “Book Unbound” project of the University of Michigan’s Humanities Collaboratory, a multi-disciplinary, multi-generational investigation into potential future forms of book-length publication that involved faculty members, students, and staff from classics, film studies, and writing studies, as well as partners from the School of Information, the Library, and the Press at University of Michigan.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Credits`}</MDXTag>
<MDXTag name="p" components={components}>{`This publication was generously funded from 2017-2019 by the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://sites.lsa.umich.edu/collaboratory/"}}>{`University of Michigan’s Humanities Collaboratory`}</MDXTag>{`. The “engagement layer” was created by a collaborative team listed below. The online project is hosted primarily on `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/"}}>{`Fulcrum, a multimedia-rich publishing platform`}</MDXTag>{` created at University of Michigan Library with support from the Andrew W. Mellon Foundation.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Developing Writers Project Team:`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Jathan Day, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Anne Gere, Director, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Michael Hoffman, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Ruth Li, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Naitnaphit Limlamai, Joint Program in English and Education, Sweetland Center for Writing, Project Manager`}</MDXTag>
<MDXTag name="p" components={components}>{`Adrienne Raw, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Naomi Silver, Associate Director, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Emily Wilson, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Site Authors:`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Jathan Day, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Michael Hoffman, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Naitnaphit Limlamai, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Content Editors:`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Jathan Day, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Anne Gere, Director, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Michael Hoffman, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Ruth Li, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Naitnaphit Limlamai, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Adrienne Raw, Joint Program in English and Education, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}>{`Naomi Silver, Associate Director, Sweetland Center for Writing`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`University of Michigan Press and Library Teams:`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Charles Watkinson, Director, University of Michigan Press`}</MDXTag>
<MDXTag name="p" components={components}>{`Melissa Baker-Young, University of Michigan Press`}</MDXTag>
<MDXTag name="p" components={components}>{`Jake Carlson, Director of Repository and Research Data Services, University of Michigan Library`}</MDXTag>
<MDXTag name="p" components={components}>{`Susan Cronin, Editorial Associate, University of Michigan Press`}</MDXTag>
<MDXTag name="p" components={components}>{`Mary Francis, Editorial Director, University of Michigan Press`}</MDXTag>
<MDXTag name="p" components={components}>{`Jonathan McGlone, Front End Developer, University of Michigan Press`}</MDXTag>
<MDXTag name="p" components={components}>{`Jeremy Morse, Director of Publishing Technology, University of Michigan Press`}</MDXTag>
<MDXTag name="p" components={components}>{`Kevin Rennells, Production Editor, University of Michigan Press`}</MDXTag>
<MDXTag name="p" components={components}>{`Jeffery Witt, Diversity Specialist, University of Michigan Library`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Design Team: `}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Kelly Hou, School of Information, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}>{`Kesava Karthik Kota, School of Information, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}>{`Kentaro Toyama, School of Information, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Video Editor:`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Tim Wilson, Web Consultant`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Illustrator and Animator: `}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Kinsey Harvey, School of Art and Design, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Web Developers:`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Ryan Lanciaux, Spaceship Studio`}</MDXTag>
<MDXTag name="p" components={components}>{`Scott Sanzenbacher, Spaceship Studio`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Special Thanks To:`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Vincent Longo, Department of Film, Television and Media, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}>{`Professor Matthew Solomon, Department of Film, Television and Media, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}>{`Professor David Stone, Department of Classical Studies and Kelsey Museum, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}>{`Professor Nicola Terrenato, Department of Classical Studies, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}>{`Tyler Johnson, Interdepartmental Program in Classical Art and Archaeology, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}>{`Matthew Naglak, Interdepartmental Program in Classical Art and Archaeology, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}>{`Zoe Ortiz, Interdepartmental Program in Classical Art and Archaeology, University of Michigan`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`HOW TO CITE THIS WEBSITE:`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`MLA: Gere, Anne Ruggles, editor. Developing Writers. U of Michigan, 4 Jan. 2019: `}<MDXTag name="a" components={components} parentName="p" props={{"href":"http://developingwritersbook.org"}}>{`http://developingwritersbook.org`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`APA: Gere, A. R. (Ed.). (2019, January 4). Developing writers. Retrieved from `}<MDXTag name="a" components={components} parentName="p" props={{"href":"http://developingwritersbook.org"}}>{`http://developingwritersbook.org`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Chicago: Gere, Anne Ruggles, ed. Developing Writers. University of Michigan. Accessed January 4, 2019. `}<MDXTag name="a" components={components} parentName="p" props={{"href":"http://developingwritersbook.org"}}>{`http://developingwritersbook.org`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"About the Study","key":"about-the-study","parentKey":"about"};

  